import dog from 'images/listing/large_default.png'
import cat from 'images/listing/cat-placeholder.png'
import bunny from 'images/listing/bunny-placeholder.png'
import formCalendar from 'images/forms/calendar25.png'

(function (window) {
  var PetRescue;

  if (!window['PetRescue']) {
    PetRescue = window['PetRescue'] = {};
  } else {
    PetRescue = window['PetRescue'];
  }

  PetRescue.env = {
    environment: 'production',
    public: true,
    countryCode: 'AU',
    paypal: {
      environment: 'live',
      clientId: 'AeLMaMHVVX61YKoNlLqoQ_1zX6MS3NBjvFZNBsrrOCgeZIeqoVwXWoVz681aMaXzSXkx2Q4CB2DzdxVV'
    },
    pinPayments: {
      sandbox: false,
      publishableKey: 'pk_iTLYM93l9FT5QutCEEkn8g'
    },
    cloudinary: {
      cloudName: 'petrescue',
      uploadPreset: 'iyiwt0mq'
    },
    google: {
      mapsAPI: 'AIzaSyBAv3xBSpJKoytnBP8zGjVS2MNr_pYdyxQ'
    },
    bugsnag: {
      public_key: '59e7bf7449f4ec980ba827f3aa0e24a5'
    },
    imagePlaceholder: {
      dog,
      cat,
      bunny,
      formCalendar
    },
    externalLinks: {
      whitelist: ["localhost", "staging.petrescue.com.au", "www.petrescue.com.au", "petrescue.com.au"]
    },
    endpoints: {
      payments: {
        create_paypal_payment: '//www.petrescue.com.au/paypal/create_payment'
      }
    }
  };
})(window);
